import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { jsonToQueryStr } from 'Helpers/helpers';
import DatePicker from 'Components/date-picker';
import loc from 'Components/languages';
import styles from './FilterInput.less';

class FilterInput extends PureComponent {
	constructor() {
		super();
		this.loadOptions = this.loadOptions.bind(this);
		this.onChangeInputHandler = this.onChangeInputHandler.bind(this);
		this.onChangeCheckboxHandler = this.onChangeCheckboxHandler.bind(this);
		this.onChangeDateHandler = this.onChangeDateHandler.bind(this);
		this.onChangeSelectHandler = this.onChangeSelectHandler.bind(this);
	}

	loadOptions(inputValue) {
		const { filter, loginInfo } = this.props;
		const url = `${process.env.API_ENDPOINT}/api/v1/report/options?${jsonToQueryStr({
			filter: filter.id,
			keyword: inputValue,
		})}`;
		const data = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${loginInfo.data.access_token}`,
			},
		};
		return fetch(url, data)
			.then((response) => response.json())
		;
	}

	onChangeInputHandler(e) {
		const { filter, onChange } = this.props;
		onChange(filter.id, e.target.value);
	}

	onChangeCheckboxHandler(e) {
		const { filter, onChange } = this.props;
		onChange(filter.id, e.target.checked);
	}

	onChangeDateHandler(date) {
		const { filter, onChange } = this.props;
		onChange(filter.id, date);
	}

	onChangeSelectHandler(selectedOption) {
		const { filter, onChange } = this.props;
		onChange(filter.id, selectedOption);
	}

	render() {
		const { filter, value, isLoading, disabled } = this.props;
		switch (filter.type) {
		case 'text':
		case 'number':
			return (
				<input
					id={ filter.value }
					type="text"
					className={ classnames('uk-input', 'uk-width-1-1', 'uk-text-center', filter.required && !value && 'uk-form-danger') }
					disabled={ isLoading || disabled }
					value={ value || '' }
					required={ filter.required }
					onChange={ this.onChangeInputHandler }
				/>
			);
		case 'checkbox':
			return (
				<div className={ styles.checkbox }>
					<input
						id={ filter.value }
						type="checkbox"
						className="uk-checkbox"
						disabled={ isLoading || disabled }
						checked={ value || false }
						onChange={ this.onChangeCheckboxHandler }
					/>
				</div>
			);
		case 'date':
			return (
				<DatePicker
					value={ value }
					onChange={ this.onChangeDateHandler }
				/>
			);
		case 'select':
			if (filter.options) {
				return (
					<Select
						isLoading={ isLoading }
						isDisabled={ isLoading || disabled }
						isClearable
						options={ filter.options }
						isMulti={ filter.multi }
						value={ value }
						placeholder={ `${loc.select}...` }
						onChange={ this.onChangeSelectHandler }
					/>
				);
			} else {
				return (
					<AsyncSelect
						cacheOptions
						defaultOptions
						loadOptions={ this.loadOptions }
						isDisabled={ isLoading || disabled }
						isClearable
						isMulti={ filter.multi }
						value={ value }
						placeholder={ `${loc.select}...` }
						onChange={ this.onChangeSelectHandler }
					/>
				);
			}
		}
	}
}

FilterInput.propTypes = {
	filter: PropTypes.object.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
	disabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		loginInfo: state.loginInfo,
	})
)(FilterInput);