import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageReports from 'Pages/reports';
import PageReportView from 'Pages/report/view';

const Report = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}`} component={ PageReports } />
			<Route exact path={`${match.url}/view`} component={ PageReportView } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Report;