exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "table._2YDLa thead {\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n  background-color: #f6f6f6;\n}\n._1s8kz {\n  height: calc(100% - 53px);\n}\n._3Sbl2 {\n  width: 100%;\n  height: 100%;\n}\n._2p86g {\n  background-color: #FFFFCB;\n  white-space: pre;\n}\n", ""]);

// Exports
exports.locals = {
	"reportDetailsTable": "_2YDLa",
	"pdfWrapper": "_1s8kz",
	"pdf": "_3Sbl2",
	"sectionTitle": "_2p86g"
};