import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map, Set } from 'immutable';
import { connect } from 'react-redux';
import classnames from 'classnames';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import parse from 'date-fns/parse';
import Modal from 'Components/modal';
import Button from 'Components/button';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import { receiveApi, convertDateForApi } from 'Helpers/helpers';
import * as cons from 'Redux/constants';
import { getReportFilters } from 'Redux/actions';
import FilterInput from './FilterInput.jsx';
import styles from './ModalReportFilter.less'; 

class ModalReportFilter extends PureComponent {
	constructor() {
		super();
		this.state = {
			filters: new Map(),
			excludes: new Set(),
			hasErrors: false,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 400px)',
				right: 'calc(50% - 400px)',
				padding: 0,
			},
		};
		this.isLoading = this.isLoading.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onChangeExcludeHandler = this.onChangeExcludeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onResetHandler = this.onResetHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		const reportFilterInfo = this.props.reportFilterInfo;
		const prevReportFilterInfo = prevProps.reportFilterInfo;
		const type = this.props.type;
		const prevType = prevProps.type;
		let newState = {};
		if (isOpen && isOpen !== prevIsOpen) {
			if (type) {
				const { reportFilterInfo } = this.props;
				if (!reportFilterInfo.dataFetched || type !== prevType) {
					this.props.getReportFilters({ payload: { type } });
				}

				if (type !== prevType) {
					newState.hasErrors = false;
					newState.filters = new Map();
					newState.excludes = new Set();
				}
			}
		}

		if (receiveApi(reportFilterInfo, prevReportFilterInfo, cons.GET_REPORT_FILTERS)) {
			let filters = {};
			let storedFilters = localStorage.getItem(`report-filter-${type}`);
			storedFilters = storedFilters ? JSON.parse(storedFilters) : {};

			reportFilterInfo.data.forEach((filter) => {
				if (storedFilters[filter.id] !== undefined || filter.default !== undefined) {
					const backupDate = new Date();
					switch (filter.type) {
					case 'date':
						filters[filter.id] = parse(storedFilters[filter.id] || filter.default, 'yyyy-MM-dd', backupDate);
						break;
					default:
						filters[filter.id] = storedFilters[filter.id] || filter.default;
						break;
					}
				}
			});

			newState.hasErrors = false;
			newState.filters = new Map(filters);
			newState.excludes = new Set(storedFilters.excludes);
		}

		if (Object.keys(newState).length > 0) {
			this.setState(newState);
		}
	}

	isLoading() {
		const { reportFilterInfo } = this.props;
		return reportFilterInfo.isFetching;
	}

	onChangeHandler(field, value) {
		const { filters } = this.state;
		this.setState({
			filters: filters.set(field, value),
		});
	}

	onChangeExcludeHandler(e) {
		const { excludes } = this.state;
		let { id } = e.target;
		id = id.replace(/-exclude$/, '');
		this.setState({
			excludes: excludes.has(id) ? excludes.delete(id) : excludes.add(id),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { type, reportFilterInfo, appLanguage } = this.props;
		let filters = this.state.filters.toJS();
		let excludes = this.state.excludes.toJS();
		let tmp = {};
		let hasErrors = false;
		reportFilterInfo.data.forEach((filter) => {
			if (filter.required && !filters[filter.id]) {
				hasErrors = true;
			}
			if (filters[filter.id]) {
				tmp[filter.id] = filters[filter.id];

				switch (filter.type) {
				case 'select':
					if (filter.multi) {
						filters[filter.id] = filters[filter.id] ? filters[filter.id].map((filter) => filter.value.trim()).join(',') : [];
					} else {
						filters[filter.id] = filters[filter.id].value.trim();
					}
					break;
				case 'date':
					filters[filter.id] = convertDateForApi(filters[filter.id], null);
					tmp[filter.id] = filters[filter.id];
					break;
				}
			} else {
				delete filters[filter.id];
				delete tmp[filter.id];
			}
		});
		tmp.excludes = excludes;
		this.setState({
			hasErrors,
		});
		if (!hasErrors) {
			let lang = 'en';
			switch (appLanguage.language) {
			case 'zh-Hant':
				lang = 'tc';
				break;
			case 'zh-Hans':
				lang = 'sc';
				break;
			}

			localStorage.setItem(`report-filter-${type}`, JSON.stringify(tmp));

			this.props.history.push('report/view?' + queryString.stringify({
				...filters,
				excludes: excludes.join(','),
				lang,
				type,
			}));
		} else {
			alert('Please fill in all required fields (*).');
		}
	}

	onResetHandler(e) {
		e.preventDefault();
		const { type, reportFilterInfo } = this.props;
		if (!reportFilterInfo.isFetching && reportFilterInfo.type === cons.GET_REPORT_FILTERS.SUCCESS) {
			let filters = {};
			reportFilterInfo.data.forEach((filter) => {
				if (filter.default !== undefined) {
					const backupDate = new Date();
					switch (filter.type) {
					case 'date':
						filters[filter.id] = parse(filter.default, 'yyyy-MM-dd', backupDate);
						break;
					default:
						filters[filter.id] = filter.default;
						break;
					}
				}
			});

			this.setState({
				hasErrors: false,
				filters: new Map(filters),
				excludes: new Set(),
			});
			localStorage.removeItem(`report-filter-${type}`);
		}
	}

	render() {
		const { isOpen, onToggle, reportFilterInfo, appLanguage } = this.props;
		const { filters, excludes, hasErrors } = this.state;
		const isLoading = this.isLoading();
		let lang = 'en';
		switch (appLanguage.language) {
		case 'zh-Hant':
			lang = 'tc';
			break;
		case 'zh-Hans':
			lang = 'sc';
			break;
		}
		return (
			<Modal
				title={ loc.reportFilters }
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<LoadingOverlay active={ isLoading } className={ styles.loadingOverlay }>
					<form onSubmit={ this.onSubmitHandler } className="uk-height-1-1">
						<div className={ styles.container }>
							{
								hasErrors && (
									<p className="uk-text-center uk-text-danger uk-margin-small-top uk-margin-remove-bottom">
										{ loc.MSGFieldsRequired }
									</p>
								)
							}
							<div className={ classnames(styles.row, styles.excl, 'uk-text-primary') }>
								<div className={ styles.rowWrapper } />
								<div className={ styles.checkboxWrapper }>
									{ loc.exclude }
								</div>
							</div>
							{
								!reportFilterInfo.isFetching && reportFilterInfo.data.map((filter, index) => (
									<div key={ `modal-report-filter-${index}` } className={ styles.row }>
										<div className={ styles.rowWrapper }>
											<div className="uk-flex">
												<div className={ classnames('uk-width-2-5', 'uk-text-primary', styles.label, filter.required && 'uk-text-bold') }>
													{ filter[`name_${lang}`] }{ filter.required ? ' *' : '' }
												</div>
												<div className={ classnames('uk-width-3-5', styles.value) }>
													<FilterInput
														filter={ filter }
														value={ filters.get(filter.id) }
														disabled={ isLoading }
														isLoading={ isLoading }
														onChange={ this.onChangeHandler }
													/>
												</div>
											</div>
										</div>
										<div className={ styles.checkboxWrapper }>
											{
												filter.excludable && (
													<div className={ styles.checkbox }>
														<input
															id={ `${filter.id}-exclude` }
															type="checkbox"
															className="uk-checkbox"
															disabled={ isLoading }
															checked={ excludes.has(filter.id) || false }
															onChange={ this.onChangeExcludeHandler }
														/>
													</div>
												)
											}
										</div>
									</div>
								))
							}

							<div className="uk-margin uk-margin-left uk-margin-right uk-text-small uk-text-muted uk-text-bold">
								{ loc.MSGFieldsRequired }
							</div>
						</div>

						<div className={ styles.button }>
							<Button
								text={ loc.update }
								theme="primary"
								type="submit"
							/>
							<Button
								text={ loc.reset }
								theme="default"
								type="reset"
								className="uk-margin-left"
								onClick={ this.onResetHandler }
							/>
						</div>
					</form>
				</LoadingOverlay>
			</Modal>
		);
	}
}

ModalReportFilter.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	type: PropTypes.number,
};

export default withRouter(connect(
	(state) => ({
		reportFilterInfo: state.reportFilterInfo,
		appLanguage: state.appLanguage,
	}),
	(dispatch) => ({
		getReportFilters: para => dispatch(getReportFilters(para)),
	})
)(ModalReportFilter));